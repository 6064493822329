<template>
  <el-dialog
    title="不合格品处理"
    width="720px"
    class="custom-dialog"
    :visible.sync="disposeVisable"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="90px">
      <el-form-item label="处理结果" prop="inspectionHandleStatus">
        <el-radio v-model="form.inspectionHandleStatus" label="4">
          重新发货
        </el-radio>
        <!-- <el-radio v-model="form.inspectionHandleStatus" label="2">
          退款
        </el-radio> -->
        <!-- TODO LABEL VALUE -->
        <el-radio v-model="form.inspectionHandleStatus" label="3">
          更换供应商
        </el-radio>
      </el-form-item>
      <!-- <div v-if="form.inspectionHandleStatus == 2">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item prop="inspectionQualifiedNum" label="合格品数量">
              <el-input v-model="form.inspectionQualifiedNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-checkbox style="margin-top: 10px" v-model="checked">
              以后处理退款
            </el-checkbox>
          </el-col>
        </el-row>
        <el-form-item prop="inspectionRefundAmount" label="退款金额">
          <el-input v-model="form.inspectionRefundAmount"></el-input>
        </el-form-item>
        <el-form-item prop="inspectionRefundRemark" label="退款说明">
          <el-input v-model="form.inspectionRefundRemark"></el-input>
        </el-form-item>
        <p>凭证/附件</p>
      </div> -->
      <div v-if="form.inspectionHandleStatus == 3">
        <el-table :data="product" border striped>
          <el-table-column label="产品编码" prop="sku"></el-table-column>
          <el-table-column label="产品名称" prop="nameCn"></el-table-column>
          <el-table-column label="规格">
            <template slot-scope="scope">
              <div v-if="scope.row.specsValue1">
                {{ scope.row.specsValue1 }}
                <span v-show="scope.row.specsValue2">
                  / {{ scope.row.specsValue2 }}
                </span>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column
            label="采购数量"
            prop="totalPurchaseNum"
          ></el-table-column>

          <el-table-column
            label="本次验货数量"
            prop="numInspection"
          ></el-table-column>
        </el-table>
        <p class="info">Tips：确定后，可在采购需求重新生成采购订单；</p>
      </div>
    </el-form>

    <div class="text-center mt20">
      <el-button @click="save()" type="primary">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { OrderListInteractor } from '@/core'
  import { changeSupplier } from '@/api/product/productList'
  import { emit } from 'process'
  export default {
    data() {
      return {
        checked: false,
        form: {
          inspectionId: '', // 验货单id
          purchaseOrderId: '', // 采购单id
          purchaseProductId: '', // 采购产品id
          inspectionHandleStatus: '4', // 验货处理结果 (1以后处理2退款3无需处理4重新发货)
          inspectionQualifiedNum: '', // 验货合格品数量
          inspectionRefundAmount: '', // 退款金额
          inspectionRefundRemark: '', // 退款说明
        },
        product: [], //处理的产品
        disposeVisable: false,
        rules: {
          inspectionHandleStatus: [
            { required: true, message: '请选择', trigger: 'blur' },
          ],
          inspectionQualifiedNum: [
            { required: true, message: '请输入合格品数量', trigger: 'blur' },
            {
              validator: this.$formValidation.isNum,
              trigger: 'blur',
            },
          ],
          inspectionRefundAmount: [
            {
              validator: this.$formValidation.isMoney,
              trigger: 'blur',
            },
          ],
          inspectionRefundRemark: [
            {
              max: 50,
              message: '退款说明字符长度不能超过50',
              trigger: 'blur',
            },
          ],
        },
      }
    },
    methods: {
      // 保存
      save() {
        this._getStrat(this.form.inspectionHandleStatus)()
        // let self = this
        // self.$refs.ruleForm.validate((valid) => {
        //   if (valid) {
        //     if (self.checked) {
        //       self.form.inspectionHandleStatus = 1
        //     }
        //     const params = { ...self.form }
        //     orderDisposalUnqualified(params).then((res) => {
        //       if (res && res.code == '000000') {
        //         self.$message.success('保存成功')
        //         self.$parent.getDetail(self.form.purchaseOrderId)
        //         self.$parent.active = '2'
        //         self.close()
        //       }
        //     })
        //   }
        // })
      },
      orderDisposalUnqualified(params) {
        params = {
          ...this.form,
          ...params,
          inspectionHandleStatus: 4,
        }

        OrderListInteractor.orderDisposalUnqualified(params).then((res) => {
          if (res && res.code == '000000') {
            this.$message.success('保存成功')
            this.$parent.getDetail(this.form.purchaseOrderId)
            this.$parent.active = '2'
            this.close()
          }
        })
      },

      /**
       * 获取确定按钮策略
       */

      _getStrat(type) {
        const type2Strats = {
          4: '_resendGoods', // 重新发货
          3: '_changeSupplier', // 切换供应商
        }
        if (!this.strats) {
          this.strats = {}
        }
        if (!this.strats[type]) {
          this.strats[type] = this[type2Strats[type]]
        }
        return this.strats[type]
      },

      // 重新发货
      _resendGoods() {
        // 显示验货通知弹窗
        this.$emit('inspection-notice', this.product[0])
      },

      // 切换供应商
      _changeSupplier() {
        const { purchaseProductId, numActual } = this.product[0]
        changeSupplier({ purchaseProductId, numInspection: numActual }).then(
          (res) => {
            if (res?.code === '000000') {
              this.$message.success('操作成功')
              this.$emit('re-get-getail')
            }

            this.close()
          }
        )
      },

      // 关闭弹窗重置表单
      close() {
        this.$refs.ruleForm.resetFields()
        this.disposeVisable = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .info {
    margin-top: 5px;
    color: #999;
    font-size: 12px;
  }
</style>
