<template>
  <el-dialog
    title="付款申请"
    width="900px"
    class="custom-dialog"
    :visible.sync="paymentVisable"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-form :model="form" ref="ruleForm">
      <table class="custom-table mb20">
        <thead>
          <tr>
            <th width="100">收款名称</th>
            <th width="200">开户银行</th>
            <th width="200">账户</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ form.accountName || '--' }}</td>
            <td>{{ form.bankName || '--' }}</td>
            <td>{{ form.bankAccount || '--' }}</td>
          </tr>
        </tbody>
      </table>

      <table class="custom-table no-space mt20">
        <thead>
          <tr>
            <th width="200">采购订单金额</th>
            <th width="200">付款节点</th>
            <th width="200">应付款金额</th>
            <th width="200">总应付金额</th>
            <th width="200">
              <span>
                <i>*</i>
                申请付款金额
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in form.applicationPayOrderChildEditDTOList"
            :key="i"
          >
            <td>{{ toFixed3(item.amountTotal) || '--' }}</td>
            <td>
              <el-select
                v-model="
                  form.applicationPayOrderChildEditDTOList[i].paymentNodeName
                "
              >
                <el-option
                  v-for="item in paymentNodeList"
                  :key="item.paymentNode"
                  :label="item.paymentNodeName"
                  :value="item.paymentNode"
                />
              </el-select>
            </td>
            <td>{{ toFixed3(item.amountPayable) || '--' }}</td>
            <td>{{ toFixed3(item.payableTotal) || '--' }}</td>
            <td>
              <el-form-item
                :prop="
                  'applicationPayOrderChildEditDTOList.' +
                  i +
                  '.amountApplication'
                "
                :rules="[
                  {
                    required: true,
                    message: '申请付款金额必填',
                    trigger: 'blur',
                  },
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: '>=0最多三位小数',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  maxlength="10"
                  v-model="
                    form.applicationPayOrderChildEditDTOList[i]
                      .amountApplication
                  "
                  @change="applyMoneyChange(i)"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="mt10 text-right red" v-show="refundFlag">
        采购订单{{ refundFlag }}有待处理退款
      </p>

      <p>备注</p>
      <el-input
        type="textarea"
        v-model="form.remark"
        maxlength="500"
        show-word-limit
        :rows="3"
        placeholder="请输入500个字符以内"
      ></el-input>
      <p>凭证/附件</p>
      <ErpUpload
        :uploadParams="uploadParams"
        @uploadList="uploadList"
      ></ErpUpload>
    </el-form>

    <div class="text-center mt20">
      <el-button @click="save()" type="primary">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 审核模板-->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" />
  </el-dialog>
</template>

<script>
  import { OrderListInteractor } from '@/core'
  import AuditChoose from '@/components/AuditChoose'
  import { mapGetters } from 'vuex'
  export default {
    components: {
      AuditChoose,
    },
    data() {
      return {
        id: '',
        form: {
          accountName: '', // 户名
          bankName: '', // 开户银行
          bankAccount: '', // 账户
          remark: '', // 备注
          fileAnnex: '', // 附件
          applicationPayOrderChildEditDTOList: [], // 付款子表
          auditObjectDTO: [], // 审核人员
          entryType: 0,
        },
        paymentNodeList: [],
        paymentVisable: false,
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        refundFlag: '',
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    methods: {
      show(id) {
        this.paymentVisable = true
        this.initLoading(id)
      },
      // 获取初始化信息
      initLoading(id) {
        let self = this
        self.paymentNodeList = []
        OrderListInteractor.orderCreatePayOrder({ businessIds: id }).then(
          (res) => {
            self.id = id
            self.form.accountName = res.data.accountName
            self.form.bankName = res.data.bankName
            self.form.bankAccount = res.data.bankAccount
            self.form.fileAnnex = res.data.fileAnnex
            self.form.applicationPayOrderChildEditDTOList =
              res.data.applicationPayOrderChildEditVOList
            res.data.applicationPayOrderChildEditVOList.forEach((item) => {
              self.paymentNodeList.push({
                paymentNode: item.paymentNode,
                paymentNodeName: item.paymentNodeName,
              })
              item.entryType = 0
            })
            self.paymentVisable = true
            self.form.entryType = 0
          }
        )
      },

      // 申请付款金额≤应付金额
      applyMoneyChange(i) {
        let list = this.form.applicationPayOrderChildEditDTOList
        if (list[i].amountApplication > list[i].payableTotal) {
          list[i].amountApplication = list[i].payableTotal.toFixed(3)
          this.$message.warning(
            this.form.entryType != 1
              ? '申请付款金额不能大于总应付金额!'
              : '申请付款金额不能大于已付金额!'
          )
        }
      },

      // 保存审核模板信息
      saveClick(data) {
        let self = this
        self.form.auditObjectDTO = data
        self.$refs['AuditChoose'].close()
        const params = { ...self.form }
        OrderListInteractor.orderApplicationPayOrderInsertPO(params).then(
          (res) => {
            if (res && res.code == '000000') {
              self.$message.success('保存成功')
              if (this.$parent.$options.name == 'IndexCard') {
                this.$emit('success')
              } else {
                self.$parent.getDetail(self.id)
                self.$parent.active = '3'
              }
              self.close()
            }
          }
        )
      },

      // 获取上传文件地址
      uploadList(fileList) {
        this.form.fileAnnex = JSON.stringify(fileList)
      },

      // 保存
      save() {
        let self = this
        self.$refs.ruleForm.validate((valid) => {
          if (valid) {
            // 配置审核模板
            let auditParams = {
              auditType: 7,
              status: 0,
              tenantId: self.userInfo.tenantId,
            }
            self.pubApi.getAuditTemplates(auditParams).then((response) => {
              if (!response.data.length) {
                self.$baseMessage(
                  '请联系管理员配置审核模板',
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
              } else {
                self.$refs['AuditChoose'].showAddEdit(auditParams)
              }
            })
          }
        })
      },

      // 关闭弹窗重置表单
      close() {
        this.$refs.ruleForm.resetFields()
        this.paymentVisable = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-dialog {
    p {
      margin: 15px 0;
    }
  }
</style>
