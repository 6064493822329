<template>
  <el-dialog
    title="发票登记"
    width="1100px"
    class="custom-dialog"
    :visible.sync="orderRegisterVisable"
    v-if="orderRegisterVisable"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="公司名称" label-width="75px" class="label-left">
            <el-input v-model="form.accountName" disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="税号" label-width="80px">
            <el-input v-model="form.dutyAccount" disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <table class="custom-table no-space">
        <thead>
          <tr>
            <th width="50">#</th>
            <th width="200">采购订单号</th>
            <th width="120">订单金额</th>
            <th width="120">已付金额</th>
            <th width="150">未付金额</th>
            <th width="150">已开票金额</th>
            <th width="150">未开票金额</th>
            <th width="200">
              <span>
                <i>*</i>
                本次开票金额
              </span>
            </th>
          </tr>
        </thead>
        <tbody v-if="form.orderRegisterList.length">
          <tr v-for="(item, i) in form.orderRegisterList" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ item.purchaseOrderNumber }}</td>
            <td>
              <template v-if="isViewCost">
                {{ item.currency === '美元' ? '$' : '￥'
                }}{{ utils.numberFormat(item.amountOrder, 2) }}
              </template>
              <NoViewCost v-else />
            </td>
            <td>
              <template v-if="isViewCost">
                {{ item.currency === '美元' ? '$' : '￥'
                }}{{ utils.numberFormat(item.amountPaid, 2) }}
              </template>
              <NoViewCost v-else />
            </td>
            <td>
              <template v-if="isViewCost">
                {{ item.currency === '美元' ? '$' : '￥'
                }}{{ utils.numberFormat(item.amountUnpaid, 2) }}
              </template>
              <NoViewCost v-else />
            </td>
            <td>
              <template v-if="isViewCost">
                {{ item.currency === '美元' ? '$' : '￥'
                }}{{ utils.numberFormat(item.amountInvoiced, 2) }}
              </template>
              <NoViewCost v-else />
            </td>
            <td class="red">
              <template v-if="isViewCost">
                {{ item.currency === '美元' ? '$' : '￥'
                }}{{ utils.numberFormat(item.amountUninvoiced, 2) }}
              </template>
              <NoViewCost v-else />
            </td>
            <td>
              <el-form-item
                :prop="'orderRegisterList.' + i + '.amountCurrent'"
                :rules="[
                  {
                    required: true,
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message: '>=0最多两位小数',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  maxlength="20"
                  v-model="form.orderRegisterList[i].amountCurrent"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="empty">
            <td colspan="8">暂无数据</td>
          </tr>
        </tbody>
      </table>
      <p class="mt10 mb20 text-right">
        开票总金额：
        <span class="green f_s_16">
          <template v-if="isViewCost">
            {{ form.orderRegisterList[0].currency === '美元' ? '$' : '￥'
            }}{{
              utils.amountTotal(form.orderRegisterList, 'amountCurrent', 2)
            }}
          </template>
          <NoViewCost v-else />
        </span>
      </p>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item
            label="发票号码"
            prop="invoiceNumber"
            label-width="80px"
          >
            <el-input v-model="form.invoiceNumber" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="发票类型" prop="invoiceType" label-width="80px">
            <el-select v-model="form.invoiceType" clearable>
              <el-option label="普通发票" value="普通发票"></el-option>
              <el-option label="增值税发票" value="增值税发票"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="开票时间" prop="invoiceDate" label-width="80px">
            <el-date-picker
              style="width: 100%"
              v-model="form.invoiceDate"
              type="date"
              placeholder="年/月/日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-form-item
          label="发票凭证"
          label-width="90px"
          prop="businessLicensePhoto"
        >
          <el-row class="el-row-upload">
            <erp-upload-images
              :allowedType="allowedType"
              :tips-visible="false"
              tip-text="支持格式：jpg,jpeg,png,gif,bmp，最多上传9张，单个文件不允许超过2MB"
              :limit="9"
              :default-file-list="enterprisePhoto"
              @change="changeUploadList"
            ></erp-upload-images>
          </el-row>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="text-center mt20">
      <el-button type="primary" @click="save">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { OrderListInteractor } from '@/core'
  import viewCost from '../utils'
  import NoViewCost from './no-view-cost'
  export default {
    data() {
      return {
        form: {
          /**
           * 基本信息
           */
          accountName: '', // 公司名称
          dutyAccount: '', // 税号
          invoiceNumber: '', // 发票号码
          invoiceType: '', // 发票类型
          invoiceDate: '', // 开票时间
          fileAnnex: '', // 附件
          orderRegisterList: [],
        },
        isViewCost: viewCost(),
        orderRegisterVisable: false,
        rules: {
          invoiceNumber: [
            { required: true, message: '请选择发票号码', trigger: 'change' },
          ],
          invoiceType: [
            { required: true, message: '请选择发票类型', trigger: 'change' },
          ],
          invoiceDate: [
            { required: true, message: '请选择开票时间', trigger: 'change' },
          ],
        },
        productListVisible: false,
        allowedType: 'jpg、 png、jpeg、gif',
        enterprisePhoto: [], //发票凭证
      }
    },
    components: {
      NoViewCost,
    },
    methods: {
      changeUploadList(list) {
        this.enterprisePhoto = []
        if (list.length) {
          list.forEach((item) => {
            this.enterprisePhoto.push(item.url)
          })
        } else {
          this.enterprisePhoto = []
          this.form.fileAnnex = ''
        }
      },

      // 获取详情
      getOrderListInvoiceDetail(ids) {
        let self = this
        OrderListInteractor.orderListInvoiceDetail({ businessIds: ids }).then(
          (res) => {
            if (res.data && res.data.length) {
              self.form.accountName = res.data[0].accountName
              self.form.dutyAccount = res.data[0].dutyAccount
              self.form.orderRegisterList = res.data || []
            } else {
              self.$message.error('暂无数据！')
              return false
            }
            self.orderRegisterVisable = true
          }
        )
      },

      // 保存
      save() {
        let self = this
        self.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if (!self.form.orderRegisterList.length) {
              self.$message.warning('至少选择一条产品')
              return false
            }

            if (self.enterprisePhoto.length) {
              self.form.fileAnnex = self.enterprisePhoto.join(',')
            }

            const form = {
              accountName: self.form.accountName,
              dutyAccount: self.form.dutyAccount,
              invoiceNumber: self.form.invoiceNumber,
              invoiceType: self.form.invoiceType,
              invoiceDate: self.form.invoiceDate,
              fileAnnex: self.form.fileAnnex,
            }
            const params = self.form.orderRegisterList.map((item) => {
              return {
                ...item,
                ...form,
              }
            })
            OrderListInteractor.orderInvoiceRegistration(params).then((res) => {
              if (res && res.code == '000000') {
                self.$message.success('保存成功')
                self.$emit('orderReg')
                self.close()
              }
            })
          } else {
            return false
          }
        })
      },

      // 删除产品
      delProduct() {
        let that = this
        that
          .$confirm(`是否确认删除?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            that.form.orderProductDTOS.splice(i, 1)
            that.$message.success('删除成功！')
          })
          .catch(() => {})
      },

      // 关闭弹窗重置表单
      close() {
        this.$refs.ruleForm.resetFields()
        this.enterprisePhoto = []
        this.orderRegisterVisable = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-dialog {
    .el-form-item {
      margin-bottom: 18px;
    }
    p {
      margin: 0 0 10px;
    }
  }
  ::v-deep {
    .el-upload--picture-card {
      width: 100px;
      height: 100px;
    }
    .el-icon-plus {
      position: relative;
      top: -20px;
    }
    .el-upload-list__item {
      width: 100px;
      height: 100px;
    }
    .el-upload-list__item-thumbnail {
      width: 100px;
      height: 100px;
    }
    .el-upload-list__item-actions {
      width: 100px;
      height: 100px;
    }
  }
</style>
