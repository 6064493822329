<template>
  <el-dialog
    title="入库异常处理"
    width="1200px"
    class="custom-dialog"
    :visible.sync="enterCargoVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <p>
      采购订单：{{ number }}
      <span class="ml20">入库单：</span>
      {{ warehouseInNumber }}
    </p>
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="处理结果" prop="expenses">
            <el-radio-group v-model="form.expenses">
              <el-radio label="供应商补货"></el-radio>
              <el-radio label="退款"></el-radio>
              <el-radio label="以后处理退款"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <div v-if="form.expenses == '退款'">
        <el-table
          class="input-table"
          :data="form.tableData"
          border
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-class-name="star"
        >
          <el-table-column
            width="120"
            prop="productCode"
            label="产品编码"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="ell">{{ scope.row.sku }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="nameCn" label="产品名称" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="ell">{{ scope.row.nameCn }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="specsValue1"
            label="规格"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="ell">
                {{ scope.row.specsValue1 }}
                <span v-show="scope.row.specsValue2">
                  ，{{ scope.row.specsValue2 }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            prop="numActual"
            label="采购数量"
          ></el-table-column>
          <el-table-column
            width="120"
            prop="numActual"
            label="申请入库数量"
          ></el-table-column>
          <el-table-column
            width="120"
            prop="numWarehouseIn"
            label="实际入库数量"
          ></el-table-column>
          <el-table-column
            width="120"
            prop="variance"
            label="入库差额数量"
          ></el-table-column>
          <el-table-column width="150" prop="numInspection" label="退款单价">
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + [scope.$index] + '.refundUnitPrice'"
                :rules="[
                  {
                    required: true,
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: '>=0最多三位小数',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  maxlength="10"
                  :disabled="true"
                  v-model="form.tableData[scope.$index].refundUnitPrice"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
        <p class="text-right">
          退款总金额：
          {{
            form.tableData.reduce((total, item) => {
              return (total += item.refundUnitPrice * item.variance)
            }, 0) | formatPrice
          }}
        </p>

        <el-row :gutter="20" class="mt20">
          <el-col :span="8">
            <el-form-item
              label="收款方式"
              label-width="80px"
              prop="financeMethodCode"
              class="label-left"
            >
              <el-select
                v-model="form.financeMethodCode"
                placeholder="请选择"
                class="w100"
                clearable
                @change="financeMethodChange"
              >
                <el-option
                  v-for="item in paymentOptions"
                  :key="item.financeSetupId"
                  :label="item.financeName"
                  :value="item.financeSetupId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="收款日期" prop="amountTime" label-width="80px">
              <el-date-picker
                v-model="form.amountTime"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="原因说明" prop="statusRemark" label-width="80px">
          <el-input
            type="textarea"
            v-model="form.statusRemark"
            maxlength="500"
            show-word-limit
            :rows="3"
            placeholder="请输入500个字符以内"
          ></el-input>
        </el-form-item>
      </div>
      <div v-else style="height: 360px"></div>
      <div v-show="form.expenses == '退款'">
        <p>附件</p>
        <ErpUpload
          ref="ErpUpload"
          :uploadParams="uploadParams"
          @uploadList="uploadList"
        ></ErpUpload>
      </div>
    </el-form>

    <div class="text-center mt20">
      <el-button type="primary" @click="save" :loading="isLoading">
        {{ isLoading ? '加载中' : '确认' }}
      </el-button>
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { OrderListInteractor } from '@/core'

  export default {
    data() {
      return {
        number: '', // 采购订单
        warehouseInNumber: '', // 入库单
        form: {
          amountTime: new Date(), // 收款日期
          expenses: '供应商补货', // 处理结果:1补货2退款3以后退款
          financeMethod: '', // 收款方式
          financeMethodCode: '', // 收款方式code
          financeUrl: '', // 凭证/附件，多个用逗号隔开
          refundUnitPrice: '', // 退款单价
          statusRemark: '', // 原因说明
          variance: '', // 差异数量(正数)
          tableData: [], // 列表数据
        },
        paymentOptions: [], // 收款方式列表
        reslutArray: [], // 传参结果
        enterCargoVisible: false,
        isLoading: false,
        rules: {
          expenses: [
            {
              required: true,
              message: '请选择处理结果',
              trigger: 'change',
            },
          ],
          financeMethodCode: [
            {
              required: true,
              message: '请选择收款方式',
              trigger: 'change',
            },
          ],
          amountTime: [
            {
              required: true,
              message: '请选择收款日期',
              trigger: 'change',
            },
          ],
          statusRemark: [
            {
              required: true,
              message: '原因说明必填',
              trigger: 'blur',
            },
          ],
        },
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        isHome: false, //是否是首页引用组件
      }
    },
    methods: {
      // 初始化加载事件
      initLoading(data) {
        this.form.tableData = data.map((item) => {
          return {
            sku: item.sku, // 产品编码
            nameCn: item.nameCn, // 产品名称
            specsValue1: item.specsValue1, // 规格1
            specsValue2: item.specsValue2, // 规格2
            numActual: item.numActual, // 申请入库数量
            numWarehouseIn: item.numWarehouseIn, // 实际入库数量
            variance: item.numActual - item.numWarehouseIn, // 入库差额数量
            refundUnitPrice: item.priceIncludingTax, // 退款单价
            purchaseOrderId: item.purchaseOrderId, // 采购单业务id
            purchaseProductId: item.purchaseProductId, //	采购产品业务id
            orderProductUniqueId: item.orderProductUniqueId, // 产品唯一编码
          }
        })
        this.enterCargoVisible = true

        // 获取支付方式
        this.getPaymentWay()
      },

      // 支付方式
      async getPaymentWay() {
        this.paymentOptions = await this.Dicts.getFinanceData(3)
      },

      // 选择收款方式
      financeMethodChange(val) {
        this.paymentOptions.find((item) => {
          if (item.financeSetupId == val) {
            this.form.financeMethod = item.financeName
          }
        })
      },

      // 获取上传文件地址
      uploadList(fileList) {
        this.form.financeUrl = JSON.stringify(fileList)
      },

      // 处理结果转化
      expensesFormat(val) {
        if (val === '供应商补货') {
          return 1
        } else if (val === '退款') {
          return 2
        } else {
          return 3
        }
      },

      // 保存
      save() {
        let self = this
        self.$refs.ruleForm.validate((valid) => {
          if (valid) {
            self.isLoading = true
            self.form.expenses = self.expensesFormat(self.form.expenses)

            let params = []
            if (self.form.expenses == 1 || self.form.expenses == 3) {
              params = self.form.tableData.map((item) => {
                return {
                  expenses: self.form.expenses,
                  purchaseOrderId: item.purchaseOrderId,
                  purchaseProductId: item.purchaseProductId,
                }
              })
            } else {
              params = self.form.tableData.map((item) => {
                return {
                  ...item,
                  amountTime: self.form.amountTime, // 收款日期
                  expenses: self.form.expenses, // 处理结果:1补货2退款3以后退款
                  financeMethod: self.form.financeMethod, // 收款方式
                  financeMethodCode: self.form.financeMethodCode, // 收款方式code
                  financeUrl: self.form.financeUrl, // 凭证/附件，多个用逗号隔开
                  statusRemark: self.form.statusRemark, // 原因说明
                }
              })
            }
            OrderListInteractor.warehousingExceptionHandling(params).then(
              (res) => {
                if (res && res.code == '000000') {
                  self.close()
                  self.$message.success('保存成功')
                  self.$parent.getDetail(params[0].purchaseOrderId)
                  self.$parent.active = '2'

                  //触发首页待办列表
                  if (this.isHome) {
                    this.$emit('fetch-data')
                  }
                  self.isLoading = false
                }
              }
            )
          } else {
            return false
          }
        })
      },

      // table添加必填样式
      star({ row, rowIndex, column, columnIndex }) {
        if (columnIndex == 7) {
          return 'star'
        }
      },

      // 关闭弹窗重置表单
      close() {
        this.isHome = false
        this.$refs.ruleForm.resetFields()
        this.form = this.$options.data().form
        this.$refs.ErpUpload.uploadListEmpty()
        this.enterCargoVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-dialog {
    p {
      margin: 10px 0;
    }
  }
  ::v-deep {
    .el-dialog__body {
      padding-top: 0px;
    }
  }
</style>
