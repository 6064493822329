<template>
  <div>
    <el-row>
      <el-table :data="salesGatheringVOS" style="width: 100%" border class="mt15">
        <el-table-column width="150" prop="paymentLink" label="收款节点" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.paymentLink || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="paymentPropor" label="比例(%)" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.paymentPropor || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="receivablePrice" label="应收金额" align="center" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.receivablePrice">
              {{ moneySign }}
            </span>
            {{ scope.row.receivablePrice | orderMoneyFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="actualReceivablePrice" label="实收金额" width="120" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <div :style="scope.row.entryType == 1 ? 'color:red' : ''">
              <span v-if="scope.row.entryType == 1">-</span>
              <span v-if="scope.row.actualReceivablePrice">
                {{ moneySign }}
              </span>
              {{ scope.row.actualReceivablePrice | orderMoneyFormat }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="actualReceivablePriceDate" label="收款时间" align="center" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.actualReceivablePriceDate || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="voucherNo" label="收款单号" align="center" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="blue-text" v-if="scope.row.voucherNo" @click="receiptNoClick(scope.row)">
              {{ scope.row.voucherNo }}
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="financeMethod" label="收款方式" align="center" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.financeMethod || '--' }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>
<script>
  export default {
    name: 'saleDetailPopver',
    data() {
      return {}
    },
    props: ['salesGatheringVOS', 'moneySign'],
    methods: {
      receiptNoClick(val) {
        this.$emit('receiptNoClick', val)
      },
    },
  }
</script>