<template>
  <el-dialog
    title="处理退款"
    width="1200px"
    class="custom-dialog"
    :visible.sync="enterCargoVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-table
        class="input-table"
        :data="form.tableData"
        border
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-class-name="star"
      >
        <el-table-column width="100" label="处理类型">异常入库</el-table-column>
        <el-table-column width="110" prop="warehouseInNumber" label="入库单号">
          <template slot-scope="scope">
            <div
              class="ell page-link"
              @click="warehouseInNumberClick(scope.row.warehouseInId)"
            >
              {{ scope.row.warehouseInNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="110"
          prop="productCode"
          label="产品编码"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="ell">{{ scope.row.sku }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="nameCn" label="产品名称" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="ell">{{ scope.row.nameCn }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="specsValue1"
          label="规格"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="ell">
              {{ scope.row.specsValue1 }}
              <span v-show="scope.row.specsValue2">
                ，{{ scope.row.specsValue2 }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          prop="numActual"
          label="采购数量"
        ></el-table-column>
        <el-table-column
          width="110"
          prop="numActual"
          label="申请入库数量"
        ></el-table-column>
        <el-table-column
          width="110"
          prop="numWarehouseIn"
          label="实际入库数量"
        ></el-table-column>
        <el-table-column
          width="110"
          prop="variance"
          label="入库差额数量"
        ></el-table-column>
        <el-table-column width="150" prop="numInspection" label="退款单价">
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + [scope.$index] + '.refundUnitPrice'"
              :rules="[
                {
                  required: true,
                  pattern: /^\d+(\.\d{1,3})?$/,
                  message: '>=0最多三位小数',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                maxlength="10"
                :disabled="true"
                v-model="form.tableData[scope.$index].refundUnitPrice"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
      <p class="text-right">
        退款总金额：
        {{
          form.tableData.reduce((total, item) => {
            return (total += item.refundUnitPrice * item.variance)
          }, 0) | formatPrice
        }}
      </p>

      <el-row :gutter="20" class="mt20">
        <el-col :span="8">
          <el-form-item
            label="收款方式"
            label-width="80px"
            prop="financeMethodCode"
            class="label-left"
          >
            <el-select
              v-model="form.financeMethodCode"
              placeholder="请选择"
              class="w100"
              clearable
              @change="financeMethodChange"
            >
              <el-option
                v-for="item in paymentOptions"
                :key="item.financeSetupId"
                :label="item.financeName"
                :value="item.financeSetupId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="收款日期" prop="amountTime" label-width="80px">
            <el-date-picker
              v-model="form.amountTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="原因说明" prop="statusRemark" label-width="80px">
        <el-input
          type="textarea"
          v-model="form.statusRemark"
          maxlength="500"
          show-word-limit
          :rows="3"
          placeholder="请输入500个字符以内"
        ></el-input>
      </el-form-item>
      <p>附件</p>
      <ErpUpload
        ref="ErpUpload"
        :uploadParams="uploadParams"
        @uploadList="uploadList"
      ></ErpUpload>
    </el-form>

    <div class="text-center mt20">
      <el-button type="primary" @click="save" :loading="isLoading">
        {{ isLoading ? '加载中' : '确认' }}
      </el-button>
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { OrderListInteractor } from '@/core'
  export default {
    data() {
      return {
        number: '', // 采购订单
        warehouseInNumber: '', // 入库单
        form: {
          amountTime: new Date(), // 收款日期
          expenses: 2, // 处理结果:1补货2退款3以后退款
          financeMethod: '', // 收款方式
          financeMethodCode: '', // 收款方式code
          financeUrl: '', // 凭证/附件，多个用逗号隔开
          refundUnitPrice: '', // 退款单价
          statusRemark: '', // 原因说明
          variance: '', // 差异数量(正数)
          tableData: [], // 列表数据
        },
        paymentOptions: [], // 收款方式列表
        reslutArray: [], // 传参结果
        enterCargoVisible: false,
        isLoading: false,
        isDetail: false, // 入口来源
        rules: {
          financeMethodCode: [
            {
              required: true,
              message: '请选择收款方式',
              trigger: 'change',
            },
          ],
          amountTime: [
            {
              required: true,
              message: '请选择收款日期',
              trigger: 'change',
            },
          ],
          statusRemark: [
            {
              required: true,
              message: '原因说明必填',
              trigger: 'blur',
            },
          ],
        },
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
      }
    },
    methods: {
      // 初始化加载事件
      initLoading(id) {
        OrderListInteractor.handleExceptionPre({ businessId: id }).then(
          (res) => {
            this.form.tableData = res.data.map((item) => {
              return {
                warehouseInNumber: item.warehouseInNumber, // 入库单号
                warehouseInId: item.warehouseInId, // 入库单id
                sku: item.sku, // 产品编码
                nameCn: item.nameCn, // 产品名称
                specsValue1: item.specsValue1, // 规格1
                specsValue2: item.specsValue2, // 规格2
                numActual: item.numActual, // 申请入库数量
                numWarehouseIn: item.numWarehouseIn, // 实际入库数量
                variance: item.numActual - item.numWarehouseIn, // 入库差额数量
                refundUnitPrice: item.priceIncludingTax, // 退款单价
                purchaseOrderId: item.purchaseOrderId, // 采购单业务id
                purchaseProductId: item.purchaseProductId, //	采购产品业务id
                orderProductUniqueId: item.orderProductUniqueId, // 产品唯一编码
              }
            })
          }
        )
        this.enterCargoVisible = true

        // 获取支付方式
        this.getPaymentWay()
      },

      // 支付方式
      async getPaymentWay() {
        this.paymentOptions = await this.Dicts.getFinanceData(3)
      },

      // 选择收款方式
      financeMethodChange(val) {
        this.paymentOptions.find((item) => {
          if (item.financeMethodCode == val) {
            this.form.financeMethod = item.financeName
          }
        })
      },

      // 获取上传文件地址
      uploadList(fileList) {
        this.form.financeUrl = JSON.stringify(fileList)
      },

      // 入库单号跳转
      warehouseInNumberClick(id) {
        let routeData = this.$router.resolve({
          path: '/stock-center/inbound-sheet-detail',
          query: { warehouseInId: id, noReturn: true },
        })
        window.open(routeData.href, '_blank')
      },

      // 保存
      save() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.isLoading = true
            let params = this.form.tableData.map((item) => {
              return {
                ...item,
                expenses: 2, // 处理结果退款
                amountTime: this.form.amountTime, // 收款日期
                financeMethod: this.form.financeMethod, // 收款方式
                financeMethodCode: this.form.financeMethodCode, // 收款方式code
                financeUrl: this.form.financeUrl, // 凭证/附件，多个用逗号隔开
                statusRemark: this.form.statusRemark, // 原因说明
              }
            })
            orderListInteractor
              .warehousingExceptionHandling(params)
              .then((res) => {
                if (res && res.code == '000000') {
                  this.$message.success('保存成功')
                  this.isLoading = false
                  if (this.isDetail) {
                    this.$parent.getDetail(params[0].purchaseOrderId)
                    this.$parent.active = '2'
                  } else {
                    this.$parent.getList()
                  }
                  this.close()
                }
              })
          } else {
            return false
          }
        })
      },

      // table添加必填样式
      star({ row, rowIndex, column, columnIndex }) {
        if (columnIndex == 9) {
          return 'star'
        }
      },

      // 关闭弹窗重置表单
      close() {
        this.$refs.ruleForm.resetFields()
        this.form = this.$options.data().form
        this.$refs.ErpUpload.uploadListEmpty()
        this.enterCargoVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-dialog {
    p {
      margin: 10px 0;
    }
  }
  ::v-deep {
    .el-dialog__body {
      padding-top: 0px;
    }
  }
</style>
