<!-- 财务中心-付款管理-付款详情 -->
<template>
  <el-dialog title="付款单详情" :visible.sync="dialogReceiptDetailVisible" width="1305px" @close="close" append-to-body
    :close-on-click-modal="false" :destroy-on-close="true">
    <div class="content">
      <el-row :gutter="20" class="detail-header">
        <el-col :span="4">
          <p>付款单号</p>
          <span>{{ form.number }}</span>
          <span>
            <el-tag type="warning" plain v-if="form.state == 1" size="small" class="ml10">
              待处理
            </el-tag>
            <el-tag type="danger" plain v-if="form.state == 3" size="small">
              已驳回
            </el-tag>
            <el-tag type="success" plain v-if="form.state == 2" size="small">
              已完成
            </el-tag>
            <el-tag type="info" plain v-if="form.state == 7" size="small">
              银行处理中
            </el-tag>
          </span>
        </el-col>
        <el-col :span="3">
          <p>付款类型</p>
          <span v-if="form.entryType == 1">采购退款</span>
          <span v-else-if="form.payType == 1">采购付款</span>
          <span v-else-if="form.payType == 2">销售退款</span>
          <span v-else-if="form.payType == 3">网拍</span>
          <span v-else-if="form.payType == 4">信用额度还款</span>
        </el-col>
        <el-col :span="5">
          <p>买方</p>
          <span>{{ form.purchaser || '--' }}</span>
        </el-col>
        <el-col :span="7">
          <p>付款总金额</p>
          <div style="display: inline-block; color: red" v-if="form.entryType == 1">
            <span style="margin-left: -5px">-</span>
            <span>
              {{ moneySign
              }}{{ utils.numberFormat(form.amountApplication, 2) }} &nbsp;
              &nbsp;
            </span>
          </div>
          <span class="green" v-else>
            {{ moneySign
            }}{{ utils.numberFormat(form.amountApplication, 2) }} &nbsp; &nbsp;
          </span>
          <div style="font-size: 12px; color: #666666; display: inline-block">
            <span>汇率：{{ form.exchangeRate }} &nbsp;&nbsp;RMB：</span>
            <div :style="
                form.entryType == 1
                  ? 'color:red;display: inline-block;'
                  : 'display: inline-block;'
              ">
              <span v-show="form.entryType == 1">-</span>
              <span>
                {{
                utils.numberFormat(
                form.amountApplication * form.exchangeRate,
                2
                )
                }}
              </span>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 底部表格 -->
      <el-row class="mt30">
        <!-- 销售退款 -->
        <div v-show="form.payType === 2">
          <div v-for="(tableData, index) in topTableData" :key="index">
            <el-table :data="[tableData]" border style="width: 100%">
              <el-table-column align="center" type="index" width="55" label="#" />
              <el-table-column width="140" prop="shoukuanStyle" align="center" label="采购订单号">
                <template slot-scope="scope">
                  <span class="blue-text">
                    <router-link target="_blank" :to="`/purchase/purchase/purchaseOrder/purchaseOrderDetail?id=${
                        scope.row.purchaseOrderId
                      }&noReturn=${true}`">
                      {{ scope.row.purchaseOrderNumber }}
                    </router-link>
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="supplierName" align="center" width="200" label="供应商名称" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-row type="flex" align="middle" justify="center" class="supplierName-row">
                    <p class="supplierName-p">
                      {{ scope.row.supplierName || '--' }}
                    </p>
                    <el-row class="test-row" v-if="scope.row.isAgreement == 1">
                      <el-tag type="danger" size="mini" class="ml5">
                        协议
                      </el-tag>
                    </el-row>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column prop="buyerName" align="center" label="采购开发"></el-table-column>

              <el-table-column prop="bankAccount" align="center" show-overflow-tooltip label="账户信息"></el-table-column>

              <el-table-column width="104" prop="amountApplication" align="center" label="付款金额">
                <template slot-scope="scope">
                  <span class="f_w_6 green" v-if="scope.row.amountApplication">
                    <span v-show="form.entryType == 1" style="margin-right: -3px">
                      -
                    </span>
                    {{ moneySign
                    }}{{ utils.numberFormat(scope.row.amountApplication, 2) }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <el-table :data="tableData.financeOrderVOList" border style="width: 100%">
              <el-table-column align="center" type="index" width="55" label="#">
                <template slot-scope="scope">
                  <span v-if="scope.$index > 9">0{{ scope.$index + 1 }}</span>
                  <span v-else>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column width="140" prop="orderCode" align="center" label="销售订单号">
                <template slot-scope="scope">
                  <span class="blue-text">
                    <router-link target="_blank" :to="`/order/orderList/orderDetail?orderCode=${
                        scope.row.orderCode
                      }&noReturn=${true}`">
                      {{ scope.row.orderCode }}
                    </router-link>
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="businessName" align="center" label="客户代表"></el-table-column>
              <el-table-column prop="area" align="center" label="地区"></el-table-column>
              <el-table-column width="120" prop="customerName" align="center" label="客户姓名"></el-table-column>
              <el-table-column width="200" prop="companyName" align="center" label="公司名称"></el-table-column>
              <el-table-column width="100" prop="orderPrice" align="center" label="信用额度">
                <template slot-scope="scope">
                  {{ scope.row.orderPrice || '--' }}
                </template>
              </el-table-column>
              <el-table-column width="100" prop="shoukuanPrice" align="center" label="剩余额度">
                <template slot-scope="scope">
                  {{ scope.row.shoukuanPrice || '--' }}
                </template>
              </el-table-column>
              <el-table-column width="100" prop="totlePrice" align="center" label="订单总金额">
                <template slot-scope="scope">
                  <el-popover placement="bottom" trigger="click">
                    <span class="blue-text" slot="reference" @click="totlePriceClcik(scope.row.orderCode)">
                      <i v-if="scope.row.totlePrice" style="font-style: normal">
                        {{ scope.row.currency === '美元' ? '$' : '￥' }}
                      </i>
                      {{ scope.row.totlePrice }}
                    </span>
                    <sale-detail-table :moneySign="moneySign2" :salesGatheringVOS="salesGatheringVOS"
                      @receiptNoClick="receiptNoClick"></sale-detail-table>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column width="100" prop="amountReceived" align="center" label="已收金额">
                <template slot-scope="scope" v-if="scope.row.amountReceived">
                  <span v-if="scope.row.currency === '人民币'">￥</span>
                  <span v-if="scope.row.currency === '美元'">$</span>
                  {{ scope.row.amountReceived || '--' }}
                </template>
              </el-table-column>
              <el-table-column width="100" prop="outstandingAmount" align="center" label="未收金额">
                <template slot-scope="scope" v-if="scope.row.outstandingAmount">
                  <span v-if="scope.row.currency === '人民币'">￥</span>
                  <span v-if="scope.row.currency === '美元'">$</span>
                  {{ scope.row.outstandingAmount || '--' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 采购付款 -->
        <div v-show="form.payType === 1">
          <div v-for="(tableData, index) in topTableData" :key="index">
            <el-table :data="[tableData]" border style="width: 100%">
              <el-table-column align="center" width="55" label="#">
                {{ index + 1 }}
              </el-table-column>
              <el-table-column width="140" prop="shoukuanStyle" align="center" label="采购订单号">
                <template slot-scope="scope">
                  <span class="blue-text">
                    <router-link target="_blank" :to="`/purchase/purchase/purchaseOrder/purchaseOrderDetail?id=${
                        scope.row.purchaseOrderId
                      }&noReturn=${true}`">
                      {{ scope.row.purchaseOrderNumber }}
                    </router-link>
                  </span>
                </template>
              </el-table-column>
              <el-table-column align="center" width="200" label="供应商名称" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-row type="flex" align="middle" justify="center" class="supplierName-row">
                    <p class="supplierName-p">
                      {{ scope.row.supplierName || '--' }}
                    </p>
                    <el-row class="test-row" v-if="scope.row.isAgreement == 1">
                      <el-tag type="danger" size="mini" class="ml5">
                        协议
                      </el-tag>
                    </el-row>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column prop="buyerName" align="center" label="采购开发"></el-table-column>
              <el-table-column prop="accountName" align="center" label="收款名称"></el-table-column>
              <el-table-column width="200" prop="bankName" align="center" label="开户行"></el-table-column>
              <el-table-column prop="bankAccount" align="center" show-overflow-tooltip label="银行账号"></el-table-column>
              <el-table-column width="200" prop="dutyAccount" align="center" label="税号"></el-table-column>
              <el-table-column width="104" prop="amountApplication" align="center" label="付款金额">
                <template slot-scope="scope">
                  <div v-if="scope.row.amountApplication && form.entryType == 1" style="color: red">
                    <span style="margin-right: -3px">-</span>
                    <span>
                      {{ moneySign
                      }}{{ utils.numberFormat(scope.row.amountApplication, 2) }}
                    </span>
                  </div>
                  <span class="f_w_6 green" v-else-if="scope.row.amountApplication">
                    {{ moneySign
                    }}{{ utils.numberFormat(scope.row.amountApplication, 2) }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <el-table :data="tableData.financeOrderVOList" border style="width: 100%">
              <el-table-column align="center" type="index" width="55" label="#">
                <template slot-scope="scope">
                  <span v-if="scope.$index < 9">0{{ scope.$index + 1 }}</span>
                  <span v-else>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column width="140" prop="orderCode" align="center" label="销售订单号">
                <template slot-scope="scope">
                  <span class="blue-text">
                    <router-link target="_blank" :to="`/order/orderList/orderDetail?orderCode=${
                        scope.row.orderCode
                      }&noReturn=${true}`">
                      {{ scope.row.orderCode }}
                    </router-link>
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="businessName" align="center" label="客户代表"></el-table-column>
              <el-table-column prop="area" align="center" label="地区"></el-table-column>
              <el-table-column width="120" prop="customerName" align="center" label="客户姓名"></el-table-column>
              <el-table-column width="200" prop="companyName" align="center" label="公司名称"></el-table-column>
              <el-table-column width="100" prop="orderPrice" align="center" label="信用额度">
                <template slot-scope="scope">
                  {{ scope.row.orderPrice || '--' }}
                </template>
              </el-table-column>
              <el-table-column width="100" prop="shoukuanPrice" align="center" label="剩余额度">
                <template slot-scope="scope">
                  {{ scope.row.shoukuanPrice || '--' }}
                </template>
              </el-table-column>
              <el-table-column width="100" prop="totlePrice" align="center" label="订单总金额">
                <template slot-scope="scope">
                  <el-popover placement="bottom" trigger="click">
                    <span class="blue-text" slot="reference" @click="totlePriceClcik(scope.row.orderCode)">
                      <i v-if="scope.row.totlePrice" style="font-style: normal">
                        {{ scope.row.currency === '美元' ? '$' : '￥' }}
                      </i>
                      {{ scope.row.totlePrice }}
                    </span>
                    <sale-detail-table :moneySign="moneySign2" :salesGatheringVOS="salesGatheringVOS"
                      @receiptNoClick="receiptNoClick"></sale-detail-table>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column width="100" prop="amountReceived" align="center" label="已收金额">
                <template slot-scope="scope" v-if="scope.row.amountReceived">
                  <span v-if="scope.row.currency === '人民币'">￥</span>
                  <span v-if="scope.row.currency === '美元'">$</span>
                  {{ scope.row.amountReceived || '--' }}
                </template>
              </el-table-column>
              <el-table-column width="100" prop="outstandingAmount" align="center" label="未收金额">
                <template slot-scope="scope" v-if="scope.row.outstandingAmount">
                  <span v-if="scope.row.currency === '人民币'">￥</span>
                  <span v-if="scope.row.currency === '美元'">$</span>
                  {{ scope.row.outstandingAmount || '--' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-row>
      <el-row></el-row>
      <el-row class="mt20 remark">
        <el-col :span="8">
          <p>备注</p>
          <tooltip-over class="c_pointer" v-if="form.remark" :content="form.remark || '--'"
            refName="tooltipOver5"></tooltip-over>
          <span style="color: #606266" v-else>无</span>
        </el-col>
        <el-col :span="8">
          <p>创建人</p>
          <span>{{ form.creator }}</span>
        </el-col>
        <el-col :span="8">
          <p>创建时间</p>
          <span>{{ form.createTime }}</span>
        </el-col>
      </el-row>
      <el-row class="mt10 remark">
        <el-col :span="24">
          <p>附件</p>

          <div v-if="form.state == 4" class="upload-item">
            <ErpUpload ref="erpUpload" :uploadParams="uploadParamsCertif2" @uploadList="uploadCertifFileList">
            </ErpUpload>
          </div>
          <div v-else-if="fileList.length">
            <div class="flie-list" v-for="(item, i) in fileList" :key="i">
              <i class="el-icon-document"></i>
              <a class="page-link ml5">
                <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                  <span class="ell">
                    <router-link :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                      {{ item.name }}
                    </router-link>
                  </span>
                </el-tooltip>
              </a>
              <span class="size ml10">
                {{ utils.calculateFileSize(item.size) }}
              </span>
              <span class="ml10">{{ item.time }}</span>
            </div>
          </div>
          <span style="color: #606266" v-else>无</span>
        </el-col>
        <el-col :span="8" v-if="form.receiptFile">
          <p>电子回单</p>

          <div>
            <router-link :to="`/preview-file/index?name=${form.number + '.pdf'}&url=${
                form.receiptFile
              }`" target="_blank">
              回单附件.PDF
            </router-link>
          </div>
        </el-col>
      </el-row>
      <el-divider />
      <PaymentDetailModelCommon :detail="form" />
      <el-row class="mb10">
        <el-col :span="24">
          <div class="mt30 text-center">
            <el-button @click="close">关 闭</el-button>
          </div>
        </el-col>
      </el-row>
    </div>

    <!--收款单详情弹窗 -->
    <ReceiptDetailModelOut ref="ReceiptDetail" />
  </el-dialog>
</template>

<script>
  import { orderGetDetailByOrderCode } from '@/api/order'
  import { getDetailForShow } from '@/api/finance/finance-payment'
  import tooltipOver from '@/components/base-tooltip'
  import PaymentDetailModelCommon from '@/views/finance-center/payment-manage/components/payment-detail-model-common'
  import { PaymentInteractor } from '@/core'
  import saleDetailTable from '@/views/audit-process/audit-manage/components/sale-detail-table.vue'
  import ReceiptDetailModelOut from '@/views/finance-center/credit-manage/components/receipt-detail-model'
  export default {
    name: 'ReceiptDetailModel',
    components: {
      tooltipOver,
      PaymentDetailModelCommon,
      saleDetailTable,
      ReceiptDetailModelOut,
    },
    data() {
      return {
        dialogReceiptDetailVisible: false, //付款单详情弹窗
        amendRow: '',
        tagStatus: 1,
        form: {},
        money: 0,
        topTableData: [],
        moneySign: '', //付款单货币符号
        moneySign2: '', //销售订单货币符号
        fileList: [],
        salesGatheringVOS: [], //点击订单总金额，查询销售单付款进程
        uploadParamsCertif2: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        financeUrl: '',
      }
    },

    methods: {
      async showReceiptDetail() {
        this.dialogReceiptDetailVisible = true
        let response = await getDetailForShow({ businessId: this.amendRow })
        if (response?.code === '000000') {
          this.form = response?.data ?? {}
          this.topTableData = response.data.payOrderChildShowVOList
          this.moneySign = this.form.currency === '美元' ? '$' : '￥'
          this.fileList = response.data.fileAnnex
            ? JSON.parse(response.data.fileAnnex)
            : []
          this.$nextTick(() => {
            if (this.$refs.erpUpload) {
              this.$refs.erpUpload.initUpload(response.data.fileAnnex, true)
            }
          })
        }

      },

      //订单总金额点击
      async totlePriceClcik(orderCode) {
        let response = await orderGetDetailByOrderCode({ orderCode: orderCode })
        if (response.code === '000000') {
          this.moneySign2 = response.data.currency === '美元' ? '$' : '￥'
          let arr =
            response.data.orderPayVOS?.map((item) => {
              return {
                ...item,
                actualReceivablePriceDate: item.payTime,
                actualReceivablePrice: item.totalPrice,
                voucherNo: item.financeCode,
                voucherStatus: item.financeStatus,
              }
            }) || []
          arr = arr.filter((ele) => {
            return ele.financeStatus == '4'
          })
          this.salesGatheringVOS = [...response.data.salesGatheringVOS, ...arr]
        }
      },

      //点击收款单号
      receiptNoClick(row) {
        this.$refs['ReceiptDetail'].showReceiptDetail(
          row.voucherNo,
          row.voucherStatus
        )
      },

      close() {
        if (this.form?.state == 4) {
          this.saveFiles()
        }
        this.form = {}
        this.topTableData = []
        this.moneySign = ''
        this.fileList = []
        this.financeUrl = ''
        this.dialogReceiptDetailVisible = false
        this.money = 0
      },
      // 文件上传
      uploadCertifFileList(fileList) {
        this.financeUrl = JSON.stringify(fileList)
      },
      async saveFiles() {
        let files = this.financeUrl
          ? this.financeUrl
          : JSON.stringify(this.fileList)
        let res = await PaymentInteractor.updatePayFile(files, this.amendRow)
        if (!res || res.code !== '000000') {
          this.$message.warning('文件关联失败')
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .content {
    padding: 0 20px 0px;
    height: 70vh;
    overflow-y: scroll;

    .detail-header {
      >.el-col {
        p {
          margin-bottom: 14px;
        }

        >span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    .remark {
      >.el-col {
        p {
          margin-bottom: 14px;
        }

        >span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    /deep/ .el-divider--horizontal {
      margin: 30px 0;
    }
  }

  ::v-deep {
    .el-table__expanded-cell {
      padding: 0 0 0 0;
    }
  }

  .upload-item {
    position: relative;

    .submit {
      position: absolute;
      left: 350px;
    }
  }

  .supplierName-row {
    .supplierName-p {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
    }
  }

  // 付款单详情
  // 此处仅处理当前页面，后面需要在custom.scss中统一处理，这里的样式可以删除
  .flie-list {
    display: flex;

    .page-link {
      flex: 1;
      overflow: hidden;
      text-align: left;

      span.ell {
        max-width: none; // 去掉max-width: 300px;
        width: 100%;

        >a {
          width: 100%;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
</style>
