<template>
  <el-dialog
    title="入库申请"
    width="1100px"
    class="custom-dialog"
    :visible.sync="enterCargoVisable"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="入库仓库" prop="warehouseId">
            <el-select v-model="form.warehouseId">
              <el-option
                v-for="item in storageList"
                :key="item.warehouseId"
                :label="item.name"
                :value="item.warehouseId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="入库时间" prop="warehouseInDate">
            <el-date-picker
              v-model="form.warehouseInDate"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-table
        class="no-space form-rule-table"
        :data="form.applicationWarehouseInProductEditDTOList"
        border
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-class-name="star"
      >
        <el-table-column
          prop="salesOrderNumber"
          label="采购需求单号"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.salesOrderNumber }}
          </template>
        </el-table-column>
        <el-table-column
          prop="productCode"
          label="产品编码"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="ell">{{ scope.row.productCode }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="nameCn" label="产品名称" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="ell">{{ scope.row.nameCn }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="specsValue1" label="规格" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="ell" v-if="scope.row.specsValue1">
              {{ scope.row.specsValue1 }}
              <span v-show="scope.row.specsValue2">
                / {{ scope.row.specsValue2 }}
              </span>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column prop="numActual" label="待入库数量"></el-table-column>
        <!-- <el-table-column label="验货结果">
          <template slot-scope="scope">
            {{ scope.row.state != 24 ? '合格' : '不合格' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="numQualified"
          label="合格品数量"
        ></el-table-column> -->
        <el-table-column prop="numWarehouseIn" label="申请入库数量">
          <template slot-scope="scope">
            <el-form-item
              :prop="
                'applicationWarehouseInProductEditDTOList.' +
                [scope.$index] +
                '.numWarehouseIn'
              "
              :rules="[
                {
                  required: true,
                  pattern: /^[1-9]\d*$/,
                  message: '请输入>0的整数',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                maxlength="10"
                v-model="
                  form.applicationWarehouseInProductEditDTOList[scope.$index]
                    .numWarehouseIn
                "
                @blur="numWarehouseInblur(scope.$index)"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="giftNum" label="赠品数量">
          <template slot-scope="scope">
            <el-form-item
              :prop="
                'applicationWarehouseInProductEditDTOList.' +
                [scope.$index] +
                '.giftNum'
              "
              :rules="[
                {
                  required: false,
                  pattern: /^[0-9]+?$/,
                  message: '请输入>=0的整数',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="
                  form.applicationWarehouseInProductEditDTOList[scope.$index]
                    .giftNum
                "
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column> -->
      </el-table>

      <p>备注</p>
      <el-input
        type="textarea"
        v-model="form.remark"
        maxlength="500"
        show-word-limit
        :rows="3"
        placeholder="请输入500个字符以内"
      ></el-input>
      <p>附件</p>
      <ErpUpload
        ref="ErpUpload"
        :uploadParams="uploadParams"
        @uploadList="uploadList"
      ></ErpUpload>
    </el-form>

    <div class="text-center mt20">
      <el-button type="primary" @click="save">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 审核模板-->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" />
  </el-dialog>
</template>

<script>
  import { OrderListInteractor } from '@/core'
  import AuditChoose from '@/components/AuditChoose'
  import { mapGetters } from 'vuex'
  export default {
    components: {
      AuditChoose,
    },
    props: {
      storageList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        form: {
          buyerId: '', // 采购开发ID
          buyerName: '', // 采购开发名称
          supplierName: '', // 供应商名称（隐藏字段）
          orderNumber: '', // 采购单号
          purchaseOrderId: '', // 采购单ID
          warehouseId: '', // 入库仓库
          warehouseInDate: '', // 入库时间
          expectDeliveryDate: '', // 期望交期
          applicationWarehouseInProductEditDTOList: [], //验货产品列表
          remark: '', // 备注
          fileUrls: '', //附件
          auditObjectDTO: {}, // 审核模板
        },
        isCheck: null, // true不需要调审核模板
        rules: {
          warehouseId: [
            { required: true, message: '请选择入库仓库', trigger: 'change' },
          ],
          warehouseInDate: [
            { required: true, message: '请选择入库时间', trigger: 'change' },
          ],
        },
        enterCargoVisable: false,
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    methods: {
      // 获取上传文件地址
      uploadList(fileList) {
        this.form.fileUrls = JSON.stringify(fileList)
      },

      // 保存
      save() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            // 判断是否为虚拟仓,虚拟仓不走审核
            const item = this.storageList.find(
              (item) => item.warehouseId === this.form.warehouseId
            )
            let isVirtual = null
            item.belong == 2 ? (isVirtual = true) : (isVirtual = false)

            if (this.isCheck || isVirtual) {
              this.saveSubmit(isVirtual)
            } else {
              let auditParams = {
                auditType: 4,
                status: 0,
                tenantId: this.userInfo.tenantId,
              }
              this.pubApi.getAuditTemplates(auditParams).then((response) => {
                if (!response.data.length) {
                  this.$baseMessage(
                    '请联系管理员配置审核模板',
                    'warning',
                    false,
                    'erp-hey-message-warning'
                  )
                } else {
                  this.$refs['AuditChoose'].showAddEdit(auditParams)
                }
              })
            }
          } else {
            return false
          }
        })
      },

      // 保存审核模板信息
      saveClick(data) {
        this.form.auditObjectDTO = data
        this.saveSubmit(false)
      },

      // 校验申请入库数量不能大于待入库数量
      numWarehouseInblur(i) {
        const _obj = this.form.applicationWarehouseInProductEditDTOList[i]

        if (_obj.numWarehouseIn > _obj.numActual) {
          _obj.numWarehouseIn = _obj.numActual
          this.$message.warning('申请入库数量不能大于待入库数量')
        }
      },

      /**
       * 提交
       * @param {bool} true:虚拟仓 不传仓库和审核模板
       */
      saveSubmit(bool) {
        let params = null
        if (bool) {
          delete this.form.warehouseId
          delete this.form.auditObjectDTO
          params = { ...this.form }
        } else {
          let warehouse = this.storageList.find((item) => {
            return item.warehouseId == this.form.warehouseId
          })
          params = {
            ...this.form,
            warehouseName: warehouse.name,
          }
        }

        OrderListInteractor.applicationWarehouseInInsertPO(params).then(
          (res) => {
            if (res && res.code == '000000') {
              this.$message.success('保存成功')
              this.$parent.getDetail(this.form.purchaseOrderId)
              this.$parent.active = '2'
              this.close()
            }
          }
        )
      },

      // table添加必填样式
      star({ row, rowIndex, column, columnIndex }) {
        if (columnIndex == 5) {
          return 'star'
        }
      },

      // 关闭弹窗重置表单
      close() {
        this.$refs.ruleForm.resetFields()
        this.form = this.$options.data().form
        this.$refs.ErpUpload.uploadListEmpty()
        this.enterCargoVisable = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-dialog {
    p {
      margin: 10px 0;
      span {
        font-size: 16px;
        font-family: 'PingFang Bold';
      }
    }
    /deep/.el-table table th.star div:before {
      content: '*';
      color: red;
    }
    /* 带表单验证的table */
    .form-rule-table {
      /deep/ {
        .cell {
          overflow: initial;
          .el-form-item__content {
            padding: 5px 0;
            .el-form-item__error {
              top: 38px;
            }
          }
        }
      }
    }
  }
</style>
