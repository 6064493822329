<template>
  <el-dialog
    title="费用单产品分摊情况"
    :visible.sync="dialogVisible"
    width="1100px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div>
      <el-row :gutter="20" class="detail-header">
        <el-col :span="8">
          <p>费用单号</p>
          <span>{{ transactionCode }}</span>
          <!-- <span class="ml10">
            <el-tag type="primary" plain v-if="status == '4'" size="small">
              审核中
            </el-tag>
            <el-tag
              type="warning"
              plain
              v-if="form.status === '1'"
              size="small"
              class="ml10"
            >
              待处理
            </el-tag>
            <el-tag type="danger" plain v-if="form.status === '3'" size="small">
              已驳回
            </el-tag>
            <el-tag type="danger" plain v-if="form.status === '5'" size="small">
              已撤销
            </el-tag>
            <el-tag type="danger" plain v-if="form.status === '6'" size="small">
              付款驳回
            </el-tag>
            <el-tag
              type="success"
              plain
              v-if="form.status === '2'"
              size="small"
            >
              已完成
            </el-tag>
          </span> -->
        </el-col>
        <el-col :span="8">
          <p>费用类型</p>
          <span>{{ typeMemo || '--' }}</span>
        </el-col>
        <el-col :span="8">
          <p>费用金额</p>
          <span :style="{ color: entryType === 1 ? 'red' : '#008000' }">
            <span v-if="entryType === 1">-</span>
            {{ currency == '人民币' ? '¥' : '$' }}{{ amount | formatPrice2 }}
          </span>
        </el-col>
      </el-row>
      <el-table border :data="tableData">
        <!-- TODO采购需求单号 -->
        <el-table-column
          prop="numberPrefix"
          align="center"
          label="采购需求单号"
          width="200px"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.purchasingDemandNumber }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="sku"
          align="center"
          label="产品编码"
        ></el-table-column>
        <el-table-column
          prop="nameCn"
          align="center"
          label="产品名称"
        ></el-table-column>
        <el-table-column align="center" label="规格" prop="specification">
          <template slot-scope="scope">
            {{ scope.row.specsValue1 }}
            <span v-show="scope.row.specsValue2">
              /{{ scope.row.specsValue2 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="订单数量"
          prop="numActual"
        ></el-table-column>
        <el-table-column align="center" label="订单金额">
          <template slot-scope="scope">
            {{ utils.numberFormat(scope.row.amountIncludingTax, 2) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="当前产品分摊金额">
          <template slot-scope="scope">
            <span :class="{ red: entryType === 1 }">
              <span v-if="entryType === 1">-</span>
              {{
                isExpense
                  ? utils.numberFormat(scope.row.expense, 2) || 0
                  : utils.numberFormat(
                      (scope.row.amountIncludingTax / totalMoney) *
                        amountActual,
                      2
                    )
              }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col :span="24">
          <div style="margin-top: 40px" class="text-center">
            <el-button @click="close" class="pl20 pr20">关 闭</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'

  export default {
    name: 'costDetail',
    data() {
      return {
        transactionCode: '', // 费用单号
        typeMemo: '', // 费用类型
        amount: '', // 费用金额
        currency: '', // 币种
        totalMoney: '', // 费用分摊总金额
        amountActual: '', // 实际交易金额
        tableData: [],
        dialogVisible: false, // 费用单详情弹窗
        entryType: '',
        isExpense: false, // 是否手动计算分摊金额
      }
    },
    methods: {
      initLoading(data) {
        this.transactionCode = data.transactionCode
        this.typeMemo = data.typeMemo
        this.amount = data.amount
        this.currency = data.currency
        this.totalMoney = data.totalMoney
        this.amountActual = data.amountActual
        this.dialogVisible = true
        this.entryType = data.entryType
        this.isExpense = data?.purchaseProductShowVOS?.length ? true : false
        this.tableData = data?.purchaseProductShowVOS?.length
          ? data.purchaseProductShowVOS
          : data.tableData
      },

      showOrderDetail({ salesOrder }) {
        navigateTo(this, {
          pageType: PAGE_TYPES.salesOrderDetail,
          orderNumber: salesOrder,
          roleJudge: false,
        })
      },

      close() {
        this.dialogVisible = false
        this.form = {}
        this.tableData = [{}]
      },
    },
  }
</script>

<style lang="scss" scoped>
  .detail-header {
    margin-bottom: 20px;
    > .el-col {
      p {
        margin-bottom: 14px;
      }
      > span {
        font-size: 16px;
        color: #000;
      }
      .green {
        color: green;
        font-weight: bold;
      }
    }
  }

  ::v-deep {
    .el-dialog__header {
      padding-bottom: 0;
      > .el-dialog__title {
        font-size: 16px;
      }
    }
  }
</style>
