<template>
  <el-dialog title="验货通知" width="1200px" class="custom-dialog" :visible.sync="collectCargoVisable"
    :close-on-click-modal="false" :before-close="close">
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-row :gutter="20" v-if="!form.isReshipment">
        <el-col :span="24">
          <el-form-item label="无需验货" style="margin-bottom: 5px" v-allowed="['NO_INSPECTION_REQUIRED']">
            <el-checkbox-group v-model="form.noInspectionRequired">
              <el-checkbox @change="checkCargo" name="noInspectionRequired"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="5">
          <el-form-item label="验货地址" prop="inspectionAddressType" label-width="80px" :rules="[
              {
                required: !form.noInspectionRequired,
                message: '请选择验货地址',
                trigger: 'change',
              },
            ]">
            <el-select placeholder="请选择" v-model="form.inspectionAddressType"
              @change="addressTypeChange(form.inspectionAddressType)">
              <el-option v-for="item in addressList" :key="item.warehouseId" :label="item.label"
                :value="item.label"></el-option>
              <!-- <el-option label="供应商地址" value="0"></el-option>
              <el-option label="交货地址" value="1"></el-option> -->
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="inspectionAddress">
            <div class="address">
              <i v-show="numInspectionRequired">*</i>
              <el-input v-model="form.inspectionAddress" placeholder="请输入" />
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="期望验货日期" prop="inspectionDate" label-width="120px">
            <el-date-picker v-model="form.inspectionDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
              style="width: 100%"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="物流公司及运单号" prop="logisticsCompanyNo" label-width="150px">
            <el-input v-model="form.logisticsCompanyNo" placeholder="请输入" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="QC" prop="inspector" label-width="80px" label-position="left">
            <el-input :disabled="!hasQCEditRight" style="width: 150px" v-model="form.inspector" placeholder="请输入"
              clearable @focus="getUser"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-table class="no-space form-rule-table" :data="form.inspectionProductEditDTOList" border
        :header-cell-style="{ textAlign: 'center' }" :cell-style="{ textAlign: 'center' }"
        :header-cell-class-name="star">
        <el-table-column width="120" prop="salesOrderNumber" label="销售订单编号" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="page-link">
              <router-link :to="`/order/orderList/orderDetail?orderCode=${scope.row.salesOrderNumber}`">
                {{ scope.row.salesOrderNumber }}
              </router-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="120" prop="productCode" label="产品编码" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="ell">{{ scope.row.productCode }}</div>
          </template>
        </el-table-column>
        <el-table-column width="180" prop="nameCn" label="产品名称" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="ell">{{ scope.row.nameCn }}</div>
          </template>
        </el-table-column>
        <el-table-column width="120" prop="specsValue1" label="规格" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="ell">
              {{ scope.row.specsValue1 }}
              {{ scope.row.specsValue2 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="120" prop="numActual" label="采购数量" show-overflow-tooltip></el-table-column>
        <el-table-column width="130" prop="provideSamples" label="是否提供样品">
          <template slot-scope="scope">
            <el-form-item>
              <el-select placeholder="请选择" v-model="
                  form.inspectionProductEditDTOList[scope.$index].provideSamples
                " clearable>
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column width="120" prop="numInspection" label="验货数量">
          <template slot-scope="scope">
            <el-form-item :prop="
                'inspectionProductEditDTOList.' +
                [scope.$index] +
                '.numInspection'
              " :rules="[
                {
                  required: true,
                  pattern: /^[1-9]\d*$/,
                  message: '请输入>0的整数',
                  trigger: 'blur',
                },
              ]">
              <el-input maxlength="10" @input="numInspectionRule(scope.$index)" v-model="
                  form.inspectionProductEditDTOList[scope.$index].numInspection
                " placeholder="请输入" clearable :disabled="form.isReshipment"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column width="120" prop="numInspection" label="检验标准">
          <template slot-scope="scope">
            <el-form-item :prop="
                'inspectionProductEditDTOList.' +
                [scope.$index] +
                '.actualInspectionStandard'
              " :rules="[
                {
                  required: numInspectionRequired,
                  message: '请选择检验标准',
                  trigger: 'change',
                },
              ]">
              <el-select placeholder="请选择" v-model="
                  form.inspectionProductEditDTOList[scope.$index]
                    .actualInspectionStandard
                " clearable>
                <el-option label="全检" value="全检"></el-option>
                <el-option label="按照QC标准检验" value="按照QC标准检验"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column width="240" prop="inspectionRequirements" label="验货要求">
          <template slot-scope="scope">
            <el-form-item :prop="
                'inspectionProductEditDTOList.' +
                [scope.$index] +
                '.inspectionRequirements'
              " :rules="[
                {
                  required: numInspectionRequired,
                  message: '验货要求必填',
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="
                  form.inspectionProductEditDTOList[scope.$index]
                    .inspectionRequirements
                " type="textarea" :rows="3" maxlength="500" show-word-limit placeholder="请输入" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column width="240" prop="packingInformation" label="装箱信息">
          <template slot-scope="scope">
            <el-form-item :prop="
                'inspectionProductEditDTOList.' +
                [scope.$index] +
                '.packingInformation'
              " :rules="[
                {
                  required: numInspectionRequired,
                  message: '装箱信息必填',
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="
                  form.inspectionProductEditDTOList[scope.$index]
                    .packingInformation
                " type="textarea" :rows="3" maxlength="500" show-word-limit placeholder="请输入" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>

      <p>备注</p>
      <el-input type="textarea" v-model="form.remark" maxlength="500" show-word-limit :rows="3"
        placeholder="请输入500个字符以内"></el-input>
      <p>附件</p>
      <ErpUpload ref="ErpUpload" :uploadParams="uploadParams" @uploadList="uploadList"></ErpUpload>
    </el-form>

    <div class="text-center mt20">
      <el-button type="primary" @click="save" :loading="isLoading">
        {{ isLoading ? '加载中' : '确认' }}
      </el-button>
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { OrderListInteractor, purchaseExamineGoods } from '@/core'

  export default {
    data() {
      return {
        form: {
          createType: 0, // 创建类型 - 0.采购验货创建,1.手动创建
          supplierId: '', // 供应商id
          supplierName: '', // 供应商名称
          buyerId: '', // 验货员id
          buyerName: '', // 验货员Name
          expectDeliveryDate: '', // 期望交期
          purchaseOrderId: '',
          purchaseOrderNumber: '',
          noInspectionRequired: '', // 无需验货
          inspectionAddressType: '', // 验货地址选择
          inspectionAddress: '', // 验货地址
          inspectionDate: new Date(), // 验货日期
          logisticsCompanyNo: '', // 物流公司及运单号
          inspectionProductEditDTOList: [], //验货产品列表
          remark: '', // 备注
          fileAnnex: '', //附件
          inspector: '',
          inspectorId: '',
          inspectionStage: 3, // 验货阶段（验货通知入口固定为3）
          inspectionSource: 1, //
          isc: false, // 是否是重新发货点击确认进入验货
        },
        hasQCEditRight: false,
        amount: 0,
        numInspectionRequired: true,
        collectCargoVisable: false,
        isLoading: false,
        rules: {
          // inspectionAddressType: [
          //   {
          //     required: true,
          //     message: '请选择验货地址',
          //     trigger: 'change',
          //   },
          // ],
          inspectionAddress: [
            { required: true, message: '请输入验货地址', trigger: 'blur' },
            {
              max: 50,
              message: '字符长度不能超过50',
              trigger: 'blur',
            },
          ],
          logisticsCompanyNo: [
            {
              max: 50,
              message: '字符长度不能超过50',
              trigger: 'blur',
            },
          ],
          inspectionDate: [
            { required: true, message: '请输入验货日期', trigger: 'blur' },
          ],
        },
        productListVisible: false,
        addressList: [
          {
            label: '供应商地址',
            address: '',
            warehouseId: '',
          },
        ], // 验货地址下拉数据
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
      }
    },
    created() {
      this.getAddressList()
    },
    methods: {
      // 获取上传文件地址
      uploadList(fileList) {
        this.form.fileAnnex = JSON.stringify(fileList)
      },
      getUser() {
        event.target.blur()

        const { inspector, inspectorId } = this.form

        this.$userChoose(this)
          .showPromisedUserAddEdit({
            str: '',
            roleId: inspector
              ? [
                {
                  id: inspectorId,
                  name: inspector,
                },
              ]
              : '',
            canSelectZeroUser: false,
            singleSelect: true,
          })
          .then((users) => {
            if (Array.isArray(users) && users.length > 0) {
              const { id, name, englishName } = users[0]
              this.form.inspectorId = id
              this.form.inspector = name
            }
          })
      },

      // 获取验货地址
      async getAddressList() {
        const res = await purchaseExamineGoods.warehouseList(0, 0)
        if (res?.data && res?.code === '000000') {
          const _arr = res.data.map((item) => {
            return {
              label: item.name,
              address: item.address,
              warehouseId: item.warehouseId,
            }
          })

          this.addressList = this.addressList.concat(_arr)
        }
      },

      // 选择验货地址
      addressTypeChange(name) {
        const _arr = this.addressList.filter((item) => item.label === name)

        this.form.inspectionAddress = _arr[0].address
      },

      // 无需验货选择事件
      checkCargo(boo) {
        if (boo) {
          this.$refs.ruleForm.rules.inspectionAddress[0].required = false
          this.$refs.ruleForm.rules.inspectionDate[0].required = false
          this.numInspectionRequired = false
        } else {
          this.$refs.ruleForm.rules.inspectionAddress[0].required = true
          this.$refs.ruleForm.rules.inspectionDate[0].required = true
          this.numInspectionRequired = true
        }
      },

      // 验货数量不能大于采购数量
      numInspectionRule(i) {
        const list = this.form.inspectionProductEditDTOList[i]
        if (list.numInspection > list.numActual) {
          list.numInspection = list.numActual
          this.$message.warning('验货数量不能大于采购数量！')
        }
      },

      // 处理不合格验货
      _handleReshipment() {
        this.$emit('handle-reshipment', {
          ...this.form,
          ...this.form.inspectionProductEditDTOList[0],
          remark: this.form.remark, //解决remark被覆盖
          fileAnnex: this.form.fileAnnex,
        })
        this.isLoading = false
        this.close()
      },

      // 保存
      save() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.isLoading = true
            // 验货日期数据处理
            if (!Object.keys(this.form.inspectionDate).length) {
              this.form.inspectionDate = this.utils.timeFormat(
                this.form.inspectionDate
              )
            }
            this.form.noInspectionRequired
              ? (this.form.noInspectionRequired = 1)
              : (this.form.noInspectionRequired = 0)
            const params = {
              ...this.form,
            }
            if (this.form.isReshipment) {
              this._handleReshipment()
              this.form.noInspectionRequired = ''
              return
            }
            OrderListInteractor.inspectionInsertPO(params).then((res) => {
              if (res && res.code == '000000') {
                this.$message.success('保存成功')
                this.$parent.getDetail(this.form.purchaseOrderId)
                this.$parent.active = '2'
                this.isLoading = false
                this.close()
              }
            })
          } else {
            return false
          }
        })
      },

      // table添加必填样式
      star({ row, rowIndex, column, columnIndex }) {
        if (columnIndex == 6) {
          return 'star'
        }
        if (
          (columnIndex == 6 ||
            columnIndex == 7 ||
            columnIndex == 8 ||
            columnIndex == 9) &&
          this.numInspectionRequired
        ) {
          return 'star'
        }
      },

      // 关闭弹窗重置表单
      close() {
        this.$refs.ruleForm.resetFields()
        this.form = this.$options.data().form
        this.$refs.ErpUpload.uploadListEmpty()
        this.collectCargoVisable = false
        this.numInspectionRequired = true
      },
    },

    watch: {
      collectCargoVisable(val) {
        if (val) {
          if (this.form.fileAnnex) {
            this.$nextTick().then(() => {
              this.$refs.ErpUpload.initUpload(this.form.fileAnnex)
            })
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-dialog {
    .address {
      position: relative;

      i {
        position: absolute;
        color: #ff4d4f;
        font-size: 12px;
        top: 0px;
        left: -10px;
      }
    }

    p {
      margin: 10px 0;

      span {
        font-size: 16px;
        font-family: 'PingFang Bold';
      }
    }

    /deep/.el-table table th.star div:before {
      content: '*';
      color: red;
    }

    /* 带表单验证的table */
    .form-rule-table {
      /deep/ {
        .cell {
          overflow: initial;

          .el-form-item__content {
            padding: 5px 0;

            .el-form-item__error {
              top: 38px;
            }
          }
        }
      }
    }
  }
</style>
