import { render, staticRenderFns } from "./dispose.vue?vue&type=template&id=141aa7aa&scoped=true&"
import script from "./dispose.vue?vue&type=script&lang=js&"
export * from "./dispose.vue?vue&type=script&lang=js&"
import style0 from "./dispose.vue?vue&type=style&index=0&id=141aa7aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "141aa7aa",
  null
  
)

export default component.exports